<template>
  <div
    class="d-flex align-items-center"
    :class="{
      'flex-row ml-2 p-1 text-break': rows,
      'flex-column p-1 p-md-2 m-1 m-md-2 cursor-pointer': !rows
    }"
    @click="btnShow = !btnShow"
    :title="file.file_name"
  >
    <div>
      <img
        :src="'/formats/' + file.file_extension + '.svg'"
        :class="{ 'icon-sm mr-2': rows, 'icon-md': !rows }"
      />
    </div>
    <div>
      <span
        class="mt-2"
        :class="{
          '': rows,
          'text-break text-file-grid text-center': !rows
        }"
      >
        <small>{{ file.file_name + "." + file.file_extension }}</small>
      </span>
    </div>
    <div
      class="text-muted d-flex align-items-center"
      :class="{ 'mt-2': !rows, 'ml-auto': rows }"
      v-if="btnShow || rows"
    >
      <ButtonCircle
        class="border border-success"
        :loading="file.loadingDownload"
        size="32"
        name="download"
        colorClass="btn-success"
        @click="$emit('download')"
        v-tooltip="fileConvertSize(file.file_size)"
      ></ButtonCircle>
    </div>
  </div>
</template>
<script>
import ButtonCircle from "@/components/bases/ButtonCircle.vue";

export default {
  components: { ButtonCircle },
  props: {
    file: {
      Type: Object,
      Requierd: true,
      Default: {}
    },
    rows: {
      Type: Boolean,
      Requierd: true,
      Default: false
    }
  },
  data() {
    return {
      btnShow: false
    };
  },
  methods: {
    fileConvertSize: function(aSize) {
      aSize = Math.abs(parseInt(aSize, 10));
      var def = [
        [1, "octets"],
        [1024, "ko"],
        [1024 * 1024, "Mo"],
        [1024 * 1024 * 1024, "Go"],
        [1024 * 1024 * 1024 * 1024, "To"]
      ];
      for (var i = 0; i < def.length; i++) {
        if (aSize < def[i][0])
          return (aSize / def[i - 1][0]).toFixed(2) + " " + def[i - 1][1];
      }
    }
  }
};
</script>
<style lang="css">
.icon-sm {
  width: 35px;
}
.icon-md {
  width: 40px;
}

.text-file-grid {
  width: 160px;
}

@media (max-width: 575.98px) {
  .doc-width {
    width: 100px;
  }
}

@media (max-width: 767.98px) {
  .doc-width {
    width: 100px;
  }
}

@media (max-width: 991.98px) {
  .doc-width {
    width: 100px;
  }
}

@media (max-width: 1199.98px) {
  .doc-width {
    width: 300px;
  }
}
</style>
