<template>
  <div class>
    <div class="d-flex align-items-center">
      <h6 class="m-0">{{ title }}</h6>
    </div>
    <div class="d-flex align-items-center" v-if="loading">
      <loader class="m-5"></loader>
      <h2 class="m-0">Chargement...</h2>
    </div>
    <div class="d-flex flex-wrap" :class="{ 'flex-column': rows }">
      <div v-show="!loading" v-for="file in files" :key="file.id">
        <documents-viewer-item
          :file="file"
          :rows="rows"
          @download="downloadDocument(file)"
          @delete="deleteDocument(file)"
        ></documents-viewer-item>
      </div>
      <div
        v-if="files.length < 1 && !loading"
        class="text-muted m-auto border-0"
      >
        Aucun fichier
      </div>
      <div v-if="errors" class="mt-2">
        <div v-for="err in errors" :key="err.id" class="text-danger">
          <small>{{ err }}</small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DocumentsViewerItem from "@/components/bases/DocumentsViewerItem.vue";
import Loader from "@/components/bases/Loader.vue";

export default {
  name: "Uploader",
  components: { DocumentsViewerItem, Loader },
  data: function() {
    return {
      files: [],
      filesToUpload: [],
      loading: false,
      progress: 0,
      rows: true,
      errors: []
    };
  },
  props: {
    id: {
      type: String,
      default: "document"
    },
    file_system: {
      type: String,
      default: ""
    },
    file_field: {
      type: String,
      default: ""
    },
    file_key: {
      type: [String, Number],
      default: ""
    },
    file_name: {
      type: String,
      default: "auto"
    },
    title: {
      type: String,
      default: ""
    }
  },
  computed: {},
  mounted() {
    this.getDocuments();
  },
  methods: {
    getDocuments: function() {
      this.loading = true;
      this.$http
        .get("my-documents-files", {
          params: {
            file_field: this.file_field,
            file_key: this.file_key
          }
        })
        .then(response => {
          this.files = response.data;
        })
        .catch(error => console.log(error))
        .finally(() => (this.loading = false));
    },

    downloadDocument: function(file) {
      let file_name = file.file_name + "." + file.file_extension;
      this.$http
        .get("/my-documents-files/" + file.id, { responseType: "blob" })
        .then(response => {
          console.log(file);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", file_name);
          document.body.appendChild(link);
          link.click();
        });
    }
  }
};
</script>
<style>
.border-toolbar {
  border-bottom: 1px solid rgb(1, 1, 1, 0.05);
}

.border-bottom-files > div:last-child {
  border-bottom: none;
}

.border-bottom-files > div {
  border-bottom: 1px solid rgb(1, 1, 1, 0.05);
}
</style>
